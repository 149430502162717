import React, { Dispatch, SetStateAction } from 'react';
import { QuestionQuiz } from '../types';
import {
	APIResourceView,
	ResourceOptionsEntity,
} from '../../../Views/Resource/View/types';

type ContextState = {
	question: QuestionQuiz;
	setQuestion: Dispatch<SetStateAction<QuestionQuiz>>;
	currentQuestion: number;
	setNextEnabled: Dispatch<SetStateAction<boolean>>;
	resource: APIResourceView.ResourceEntity;
	questions: QuestionQuiz[];
	timeQuiz: number;
	nextQuestion: (args: { questionId: string; selectedOption: string }) => void;
	isSavingAnswer: boolean;
	nextEnabled: boolean;
	setStateQuiz: Dispatch<SetStateAction<'alert' | 'start' | 'confirm' | 'end'>>;
	stateQuiz: 'alert' | 'start' | 'confirm' | 'end';
	selectedOption: string;
	setSelectedOption: Dispatch<SetStateAction<string>>;
	resourceProgress?: APIResourceView.ResourceProgressEntity<string>;
	setResourceProgress: (
		args: APIResourceView.ResourceProgressEntity<string>
	) => void;
	handleQuizStart: () => void;
	onTimeUpdate: (arg: number) => void;
	handleQuizReset: () => void;
	setFeedbackOptions: (args: ResourceOptionsEntity[]) => void;
	feedbackOptions: ResourceOptionsEntity[] | undefined;
	message?: string;
};

const QuizContext = React.createContext<ContextState>({
	question: {} as QuestionQuiz,
	setQuestion: () => {},
	currentQuestion: 0,
	setNextEnabled: () => {},
	resource: {} as APIResourceView.ResourceEntity,
	questions: [],
	timeQuiz: 0,
	nextQuestion: () => {},
	isSavingAnswer: false,
	nextEnabled: false,
	setStateQuiz: () => {},
	stateQuiz: 'alert',
	selectedOption: '',
	setSelectedOption: () => {},
	resourceProgress: undefined,
	setResourceProgress: () => {},
	handleQuizStart: () => {},
	onTimeUpdate: () => {},
	handleQuizReset: () => {},
	setFeedbackOptions: () => {},
	feedbackOptions: undefined,
	message: undefined,
});

export const useQuizContext = () => {
	if (!React.useContext(QuizContext)) {
		throw Error('QuizContext is undefined');
	}
	return React.useContext(QuizContext);
};

export const QuizProvider = QuizContext.Provider;
export default QuizContext;
