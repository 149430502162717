import '@fontsource/pt-sans';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { HelmetProvider } from 'react-helmet-async';

import Router from './Router';
import StoreProvider from './Store/StoreProvider';
import { AppConfig } from './Components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { bugSnagKey } from './Utils/env';
import { AlertWrapper } from './Components/alerts-manager/AlertsManager';
import { FiveHundred } from './Views/errors/500/FiveHundred';

import './I18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

export const bugSnagClient = Bugsnag.start({
	apiKey: bugSnagKey,
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: ['production'],
});

const ErrorBoundary = bugSnagClient
	.getPlugin('react')
	?.createErrorBoundary(React);

const queryClient = new QueryClient();

function App() {
	return (
		<div className="App">
			<HelmetProvider>
				<QueryClientProvider client={queryClient}>
					<StoreProvider>
						<AppConfig>
							<Router />
							<AlertWrapper />
						</AppConfig>
					</StoreProvider>
					<ReactQueryDevtools position="bottom-right" />
				</QueryClientProvider>
			</HelmetProvider>
		</div>
	);
}

const BugsnagApp = () => {
	if (ErrorBoundary && import.meta.env.PROD) {
		return (
			<ErrorBoundary FallbackComponent={FiveHundred}>
				<App />
			</ErrorBoundary>
		);
	}

	return <App />;
};

export default BugsnagApp;
