import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../stores/alerts';
import { MyAlert } from '../my-alert/MyAlert';

type AlertPortalProps = {
	children: React.ReactNode;
};

const AlertPortal = ({ children }: AlertPortalProps) => {
	const alertPortalRoot = document.getElementById('alert-portal');

	if (!alertPortalRoot) {
		return null;
	}

	return createPortal(<>{children}</>, alertPortalRoot);
};

// Rest of your code stays the same
export const AlertsManager = () => {
	const { t } = useTranslation('Common');
	const [alert, cleanAlert] = useAlerts((state) => [
		state.alert,
		state.cleanAlert,
	]);

	const action = {
		click: () => {
			alert?.action?.click && alert?.action.click();
			cleanAlert();
		},
		label: alert?.action?.label ?? t('Accept'),
	};

	return (
		<AlertPortal>
			<MyAlert
				isOpen={!!alert}
				message={alert?.message}
				title={alert?.title}
				action={action}
			/>
		</AlertPortal>
	);
};

export const AlertsPortal = () => {
	return <div id="alert-portal" />;
};

export const AlertWrapper = () => {
	return (
		<React.Fragment>
			<AlertsPortal />
			<AlertsManager />
		</React.Fragment>
	);
};
