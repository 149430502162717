import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './locale/en';
import { es } from './locale/es';

declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false;
	}
}

i18n.use(initReactI18next).init({
	resources: {
		en,
		es,
	},
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
	returnNull: false,
	ns: Array.from(new Set([...Object.keys(en), ...Object.keys(es)])),
});

export default i18n;
