export const Quiz = {
	Question: 'Pregunta {{currentQuestion}}',
	'Question of': 'Pregunta {{currentQuestion}} de {{totalQuestions}}',
	Answer: 'Responder',
	Saving: 'Guardando...',
	'Saving...': 'Guardando...',
	'Click on the Finish button to see the score of the quiz.':
		'Haz clic en el botón Finalizar para calificar tu evaluación.',
	'Finish quiz': 'Finalizar',
	'Quiz result': 'Resultados de la evaluación',
	'Passed exam': 'Aprobado',
	'Failed exam': 'Reprobado',
	'Total Questions': 'Cantidad de preguntas',
	Correct_one: 'Correcta',
	Correct_other: 'Correctas',
	Incorrect_one: 'Incorrecta',
	Incorrect_other: 'Incorrectas',
	'% of Correct': '% de correctas',
	Score: 'Puntaje',
	Next: 'Siguiente',
	'This quiz has a time limit to answer, after that it will finish automatically.':
		'Esta prueba tiene un límite de tiempo para responder, luego de eso terminará automáticamente.',
	'Start your quiz': 'Comienza la prueba',
	Retry: 'Reintentar',
	Remaining: 'Restante',
	Resume: 'Resumen',
	Feedback: 'Retroalimentación',
	'Quiz feedback': 'Retroalimentación de la evaluación',
	'Go back': 'Regresar',
};
